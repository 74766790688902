import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const AppointmentS2 = () => {
  const [forms, setForms] = useState({
    név: "",
    email: "",
    tárgy: "",
    telefonszám: "",
    üzenet: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        név: "",
        email: "",
        tárgy: "",
        telefonszám: "",
        üzenet: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <section className="wpo-contact-section-s2 section-padding">
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title-s2">
                  <span>Kapcsolatfelvétel</span>
                  <h2>Küldjön nekünk üzenetet! Vagy <a href="tel:+36703443664">hívjon</a> minket!</h2>
                </div>
                <form
                  onSubmit={(e) => submitHandler(e)}
                  className="contact-validation-active"
                >
                  <div className="row">
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <label>Teljes Név*</label>
                        <input
                          className="form-control"
                          value={forms.name}
                          type="text"
                          name="name"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Név"
                        />
                        {validator.message(
                          "name",
                          forms.name,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <label>Email*</label>
                        <input
                          className="form-control"
                          value={forms.email}
                          type="email"
                          name="email"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Email"
                        />
                        {validator.message(
                          "email",
                          forms.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <label>Telefonszám*</label>
                        <input
                          className="form-control"
                          value={forms.phone}
                          type="phone"
                          name="phone"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Telefonszám amin kereshetünk"
                        />
                        {validator.message(
                          "phone",
                          forms.phone,
                          "required|phone"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <label>Milyen ügyben keresel minket?*</label>
                        <select
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.subject}
                          type="text"
                          name="subject"
                        >
                          <option>Válassz</option>
                          <option>Szolgáltatás megrendelés</option>
                          <option>Árajánlat kérés</option>
                          <option>Kérek visszahívást</option>
                          <option>Panasz</option>
                          <option>Egyéb</option>
                        </select>
                        {validator.message(
                          "subject",
                          forms.subject,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                    <div className="col fullwidth col-lg-12 ">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.message}
                          type="text"
                          name="message"
                          placeholder="üzenet"
                        ></textarea>
                        {validator.message(
                          "message",
                          forms.message,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="submit-area">
                    <button type="submit" className="theme-btn">
                      Küldés
                    </button>
                  </div>
                </form>

                <div className="border-style"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppointmentS2;
