import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo2.png";
import Services from "../../api/service";
import icon2 from '../../images/icon/2.png'

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p>
                  Mínőségi duguláselhárítást vállalunk Budapest összes
                  kerületében és Pest megyében!
                </p>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Kapcsolat </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Budapest & Pest
                      megye
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>
                      <a href="tel:+36702834926">+36 70 344 36 64</a>
                    </li>
                    <li>
                      <i className="fi flaticon-send"></i>
                      azonnalidugulasbdavid@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12"></div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Services </h3>
                </div>
                <ul>
                  {Services.slice(0, 5).map((service, srv) => (
                    <li key={srv}>
                      <Link
                        to={`/service-single/${service.Id}`}
                      >
                        {service.sTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                <a href="tel:+36703443664"><i><img src={icon2} alt=""/></i> +36 70 344 3664</a>
                . Minden jog fenntartva.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
