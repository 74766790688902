import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage3 from '../HomePage3';
import AboutPage from '../AboutPage/AboutPage';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import ProjectPage from '../ProjectPage/ProjectPage';
import AppointmentPage from '../AppointmentPage/AppointmentPage';
import TermPage from '../TermPage/TermPage';
import PricingPage from '../PricingPage/PricingPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';
const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage3 />} />
          <Route path='home' element={<HomePage3 />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='service-s2' element={<ServicePageS2 />} />
          <Route path='service-single/:id' element={<ServiceSinglePage />} />
          <Route path='project' element={<ProjectPage />} />
          <Route path='appointment' element={<AppointmentPage />} />
          <Route path='terms' element={<TermPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='pricing' element={<PricingPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
