import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.png'
import About from '../../components/about/about';
import FunFact from '../../components/FunFact/FunFact';
import Testimonial from '../../components/Testimonial/Testimonial';
import ServiceSection from '../../components/ServiceSection/ServiceSection';

const AboutPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Rólunk'} pagesub={'Rólunk'}/> 
            <About/>
            <ServiceSection/> 
            <FunFact/>
            <Testimonial/> 
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default AboutPage;
