import React from "react";
import { Link } from "react-router-dom";

const PricingSection = (props) => {
  return (
    <section className="wpo-pricing-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <h2>Áraink</h2>
              <p style={{fontWeight: '700', fontSize: '16px'}}>Alább láthatja árainkat, a kiszállás ingyenes. Munkáinkra a helyszínen meghatározott mértékű garanciát vállalunk. (Más egy frissen lerakott cső, és egy régi javítása is)</p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div class="container">
              <div class="price-table">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Megnevezés</th>
                      <th scope="col">Irányár</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mosogató Dugulás</td>
                      <td>5990Ft-tól</td>
                    </tr>
                    <tr>
                      <td>Fürdőszobai csapok</td>
                      <td>6990Ft-tól</td>
                    </tr>
                    <tr>
                      <td>Wc | Piszoár | Toilet (alsó lefolyású is)</td>
                      <td>8990Ft-tól</td>
                    </tr>
                    <tr>
                      <td>Kamerás Csatorna Vizsgálat</td>
                      <td>9990Ft-tól</td>
                    </tr>
                    <tr>
                      <td>Csatorna kiépítés</td>
                      <td>9900Ft-tól / méter</td>
                    </tr>
                    <tr>
                      <td>Womás Duguláselhárítás</td>
                      <td>14990Ft-tól</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
