import simg from '../images/service/1.webp'
import simg2 from '../images/service/2.webp'
import simg3 from '../images/service/3.webp'
import simg4 from '../images/service/4.webp'
import simg5 from '../images/service/3.webp'
import simg6 from '../images/service/6.webp'

import sIcon1 from '../images/icon/kitchen-utensils.png'
import sIcon2 from '../images/icon/gas-stove.png'
import sIcon3 from '../images/icon/water-tap.png'
import sIcon4 from '../images/icon/sink.png'
import sIcon5 from '../images/icon/basement.png'
import sIcon6 from '../images/icon/remodeling.png'

import sSingleimg1 from '../images/service-single/1.webp'
import sSingleimg2 from '../images/service-single/2.webp'



const Services = [
    {
        Id: '1',
        sImg:simg,
        sIcon:sIcon1,
        sTitle: 'Konyhai Duguláselhárítás',
        description:'A konyhai dugulások váratlanul jelentkezhetnek, és rendkívül kellemetlenek tudnak lenni. Szolgáltatásunk célja, hogy a konyhai dugulásokat professzionális eszközökkel és tapasztalt szakemberekkel szüntessük meg, biztosítva, hogy a konyha újra használható legyen. Jelei lehetnek a lassú vízlefolyás, vízvisszafolyás, kellemetlen szagok vagy csörgő hangok. A dugulásokat leggyakrabban ételmaradékok, zsír és olaj okozzák. Szolgáltatásunk magában foglalja a problémafelmérést, mechanikai és kémiai tisztítást, valamint víznyomásos tisztítást is. Tapasztalt szakembereink gyors és hatékony megoldást nyújtanak minden konyhai dugulásra',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'Mosogató duguláselhárítás',
            'Mosogatógép csatlakozási problémák elhárítása',
            'Lefolyócsövek tisztítása',
            'Konyhai szifon tisztítása',
            'Zsírfogó karbantartás és tisztítás'
        ]
    },
    {
        Id: '2',
        sImg:simg2,
        sIcon:sIcon2,
        sTitle: 'Fővezeték Duguláselhárítás',
        description:'A fővezeték dugulása komoly problémákat okozhat az egész ház vízelvezető rendszerében, és gyors, szakszerű beavatkozást igényel. A fővezeték elzáródása általában a csatornahálózat különböző szakaszaiban felgyülemlett szennyeződések, törmelék vagy gyökérbenövések következménye. Szolgáltatásunk teljes körű megoldást kínál, amely magában foglalja a problémamegállapítást, kamerás csővizsgálatot, mechanikai és magas nyomású tisztítást. Tapasztalt szakembereink modern eszközökkel biztosítják, hogy a fővezeték ismét akadálytalanul működjön, minimalizálva az esetleges károkat és a jövőbeni problémák kialakulását',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'Fővezetékek kamerás vizsgálata',
            'Magas nyomású vízsugaras tisztítás',
            'Gyökérbenövések eltávolítása',
            'Szennyvízvezeték tisztítása',
            'Korróziós problémák elhárítása'
        ]
    },
    {
        Id: '3',
        sImg:simg3,
        sIcon:sIcon3,
        sTitle: 'Vízvezetékek szerelése / telepítése',
        description:'A vízvezeték rendszer megfelelő telepítése és karbantartása elengedhetetlen a biztonságos és zavartalan vízellátás érdekében. Legyen szó új építésről, felújításról vagy javításról, szakértő csapatunk modern technológiákkal és minőségi anyagokkal végzi a vízvezetékek szerelését és telepítését. Szolgáltatásunk kiterjed a csőhálózat kiépítésére, csaptelepek, WC-k, zuhanyzók, és egyéb vízvezeték elemek felszerelésére, valamint szivárgások és egyéb hibák javítására. Tapasztalt szakembereink gondoskodnak arról, hogy a rendszer hosszú távon megbízhatóan működjön, és megfeleljen az összes biztonsági előírásnak.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'Új vízvezeték rendszer tervezése és telepítése',
            'Csaptelepek és zuhanyzók beszerelése',
            'WC-k és mosdók telepítése',
            'Vízvezeték csövek cseréje és szerelése',
            'Vízvezeték rendszerek nyomáspróbája'
        ]
    },
    {
        Id: '4',
        sImg:simg4,
        sIcon:sIcon4,
        sTitle: 'Fürdőszobai Duguláselhárítás',
        description:'A fürdőszobai dugulások gyors és hatékony elhárítása kulcsfontosságú a mindennapi kényelem biztosításához. A zuhanyzó, kád, mosdó vagy WC lefolyóinak elzáródása gyakran a szappan, hajszálak, és egyéb lerakódások miatt alakul ki, ami kellemetlen szagokat és visszafolyó vizet eredményezhet. Szakértő csapatunk speciális eszközökkel és technikákkal dolgozik, hogy a dugulást megszüntessük, és a fürdőszobát újra teljes mértékben használhatóvá tegyük. Szolgáltatásunk magában foglalja a probléma azonosítását, mechanikai tisztítást, szükség esetén vegyszeres kezelést, és a rendszer teljes átöblítését a jövőbeni problémák megelőzése érdekében.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'WC duguláselhárítás',
            'Kádak duguláselhárítása',
            'Zuhanyzók lefolyóinak tisztítása',
            'Mosdók és bidék duguláselhárítása',
            'Piszoárok duguláselhárítása'
        ]
    },
    {
        Id: '5',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Új vezetékek telepítése',
        description:'Az új vízvezetékek telepítése kulcsfontosságú bármilyen építkezés vagy felújítás során, hogy biztosítsuk a rendszer hatékony és biztonságos működését. Szolgáltatásunk kiterjed a teljes vízvezetékrendszer megtervezésére, a csövek pontos és szakszerű telepítésére, valamint az összes szükséges szerelvény beszerelésére. Modern technológiát és kiváló minőségű anyagokat használunk, hogy a vízvezeték rendszer hosszú távon megbízható legyen, és megfeleljen minden előírásnak. Legyen szó lakó- vagy ipari épületről, szakértő csapatunk gondoskodik arról, hogy az új vezetékek problémamentesen működjenek.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'Új vízvezetékek tervezése és telepítése',
            'Vízcsövek szerelése és beszerelése',
            'Vízfogyasztó berendezések bekötése',
            'Szerelvények és szelepek telepítése',
            'Új vízvezeték rendszerek nyomáspróbája'
        ]
    },
    {
        Id: '6',
        sImg:simg6,
        sIcon:sIcon6,
        sTitle: 'Régi vezetékek felújítása',
        description:'A meglévő vízvezetékek felújítása elengedhetetlen a rendszer hosszú élettartamának és megbízhatóságának megőrzéséhez. Idővel a csövek elöregednek, szivárgások, korrózió és egyéb problémák jelentkezhetnek, amelyek komoly károkat okozhatnak. Szolgáltatásunk teljes körű felújítást kínál, amely magában foglalja a régi vezetékek cseréjét, a szivárgások kijavítását, és a rendszer korszerűsítését. Szakértő csapatunk gyorsan és hatékonyan dolgozik, minimalizálva a kellemetlenségeket, és biztosítva, hogy a felújított vezetékek megfeleljenek a legmagasabb minőségi és biztonsági követelményeknek.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        servicesPoint: [
            'Régi vízvezetékek felmérése és felújítása',
            'Elöregedett csövek cseréje',
            'Szivárgások és repedések kijavítása',
            'Korrózió eltávolítása és védelem biztosítása',
            'Vízvezeték rendszer korszerűsítése'
        ]
    }
]
export default Services;