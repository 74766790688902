import pimg from '../images/projects/img-1.jpg'
import pimg2 from '../images/projects/img-6.jpg'
import pimg3 from '../images/projects/img-2.jpg'
import pimg4 from '../images/projects/img-7.jpg'
import pimg5 from '../images/projects/img-8.jpg'
import pimg6 from '../images/projects/img-9.jpg'
import pimg7 from '../images/projects/img-3.jpg'
import pimg8 from '../images/projects/img-10.jpg'
import pimg9 from '../images/projects/img-11.jpg'



import sSingleimg1 from '../images/project-single/2.jpg'
import sSingleimg2 from '../images/project-single/3.jpg'



const Projects = [
    {
        Id: '1',
        pImg:pimg,
        subTitle:'Duguláselhárítás',
        title: 'Water Line Repair',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '2',
        pImg:pimg7,
        subTitle:'Duguláselhárítás',
        title: 'Basement Plumbing',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '3',
        pImg:pimg3,
        subTitle:'Duguláselhárítás',
        title: 'Remodeling Service',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '4',
        pImg:pimg4,
        subTitle:'Plumbing',
        title: 'Water Line Repair',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '5',
        pImg:pimg5,
        subTitle:'Plumbing',
        title: 'Kitchen Plumbing',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '6',
        pImg:pimg6,
        subTitle:'Plumbing',
        title: 'Gas Line Services',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '7',
        pImg:pimg2,
        subTitle:'Plumbing',
        title: 'Kitchen Plumbing',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '8',
        pImg:pimg8,
        subTitle:'Plumbing',
        title: 'Basement Plumbing',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
    {
        Id: '9',
        pImg:pimg9,
        subTitle:'Plumbing',
        title: 'Remodeling Service',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
        spImg1:sSingleimg1,
        spImg2:sSingleimg2,
    },
]
export default Projects;