import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Benefits = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Tényleg ingyenes a kiszállás? Hova hivhatom önöket?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                   A kiszállás teljesen ingyenes, ez alól csak akkor van kivétel, ha a munka nem a mi hibánkból hiúsul meg. Kiszállunk Budapesten és Pest megyében bárhová.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}>Vállalnak Garanciát?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Igen. A garancia mértéke változó annak függvényében, hogy például egy új csövezetéknél, ha mi végezzük a telepítést, akkor természetesen hosszú távú garanciát vállalunk, természetesen rendeltetésszerű használatra. Azonban, ha egy elöregedett csövet javasolnánk cserélni, de az ügyfél ezt nem kívánja megtenni, akkor rövidebb garanciát tudunk adni. Illetve sajnos előfordulhat, hogy a lefolyó nem oda való tárgyak miatt dugul el; erre felhívjuk az ügyfél figyelmét. Ha a lefolyó ugyanazon okból dugul el újra, természetesen nem tudjuk vállalni a garanciát, és az ügyfélnek ki kell fizetnie a munkadíjat.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography className={classes.heading}>Mikor szálnak ki?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Az év minden napján, 0-24 óráig rendelkezésre állunk. Ez alól a karácsony és a nagyobb ünnepek sem jelentenek kivételt. Általában 1, esetenként 2 órán belül elhárítjuk a dugulást.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Benefits;