import React from 'react'
import abimg from '../../images/about.webp'
import sign from '../../images/signeture.png'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="vizcsövek"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-section-title-s2">
                                    <h2>Több mint 25 éves tapasztalat duguláselhárításban</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p> <strong>Pest megyében és Pesten</strong> kínálunk  <strong>Ingyenes kiszállással</strong> profi duguláselhárítást.</p>
                                    <p>Amennyiben bármilyen vizvezetékekkel vagy dugulással kapcsolatos problémája akad keressen minket bizalommal! Azonnal indulunk, a nap és az év bármelyik
                                        szakaszában és 1 - 2 órán belül elhárítjuk a problémáját. Mindezt ingyenes kiszállással, Garanciával és korrekt árakon végezzük. Több mint 25+ év tapasztalat van mögöttünk, 
                                        kis és nagy problémákra is tudjuk a megoldást! Fix pontos árakért telefonáljon most!</p>
                                        <ul>
                                            <li> <strong style={{fontSize: '18px'}} > - Ingyenes kiszállás</strong> </li>
                                            <li> <strong style={{fontSize: '18px'}} > - Garancia - Tehát ha a probléma visszajön, ingyen megcsináljuk.</strong> </li>
                                            <li> <strong style={{fontSize: '18px'}} > - Éjjel - Nappal és Ünnepnapokon is felár nélkül.</strong> </li>
                                            <li> <strong style={{fontSize: '18px'}} > - Maximum 2 órán belül megoldjuk a problémáját</strong> </li>
                                            <li> <strong style={{fontSize: '18px'}} > - Kedvező Árakkal dolgozunk</strong> </li>
                                        </ul>
                                    <div className="signeture">
                                        <button style={{marginRight: '20px'}} className="btb theme-btn"><a style={{color: 'white', fontSize: '15px'}} href="tel:+36703443664">Hivjon Fix árakért!</a></button>
                                        <span><img src={sign} alt=""/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;