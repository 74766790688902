import React from "react";
import { Link } from 'react-router-dom'


const Hero3 = () => {
    return (
        <section className="wpo-hero-section-2">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-5 col-lg-5 offset-lg-7 col-12">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-subtitle">
                                <span>Duguláselhárítás Pest megye </span>
                            </div>
                            <div className="wpo-hero-title">
                                <h2>Duguláselhárítás <span>Budapesten</span> És Pest megyében.</h2>
                            </div>
                            <div className="wpo-hero-des">
                                <p style={{fontSize: '20px'}}>Duguláselhárítás Éjjel-Nappal akár azonnal is! Kedvező árak már 5990Ft-tól!
                                </p>
                            </div>
                            <div className="btns">
                                <ul>
                                    <li style={{marginRight: '10px'}}>
                                        <button className="btb theme-btn"><a style={{color: 'white', fontSize: '15px'}} href="tel:+36703443664">Hivjon Fix árakért!</a></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
                <div className="right-img">
                </div>
            </div>
        </section>
    )
}



export default Hero3;